export const DARK_THEME = {
  backgroundColor: "#121212",
  typographyColor: "#FFFFFF",
  overlayColor: "#FFFFFF",
  overlayOpacity: 0.1,
};

export const LIGHT_THEME = {
  backgroundColor: "#FFFFFF",
  typographyColor: "#121212",
  overlayColor: "#121212",
  overlayOpacity: 0.1,
};
