import Skeleton from "antd/lib/skeleton";
import classNames from "classnames";
import useIntersection from "hooks/useIntersection";
import React, { useState, useEffect } from "react";
import { transformImageSize } from "utils/photo";
import { isValidHttpUrl } from "utils/url";

interface ImageProgessiveProps {
  src: string;
  className?: string;
  imageClassName?: string;
  imageThumbClassName?: string;
  skeletonClassName?: string;
  shape?: "square" | "circle";
  width: number;
  height: number;
  borderRadius?: number;
  background?: string;
  border?: string;
  ratio?: number;
  threshold?: number | number[];
  onClick?: () => void;
  style?: any;
  borderTopLeftRadius?: number;
  borderBottomLeftRadius?: number;
  showBorder?: boolean;

  __hotfixIsTankhouse?: boolean;
}

const ImageProgessive: React.FC<ImageProgessiveProps> = ({
  ratio = 0.99,
  threshold = 1,
  className,
  imageClassName,
  imageThumbClassName,
  skeletonClassName,
  borderRadius,
  src,
  width,
  height,
  shape = "square",
  background = "initial", // "white",
  border = "1px solid #E5E5E5 ",
  borderTopLeftRadius,
  borderBottomLeftRadius,
  showBorder = false,
  onClick = () => {},
  style,
  __hotfixIsTankhouse = false,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: threshold,
  });

  useEffect(() => {
    if (intersection && intersection?.intersectionRatio > ratio) {
      setIsInView(true);
    }
    return () => {};
  }, [intersection, ratio]);

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  return (
    <React.Fragment>
      <div
        onClick={onClick}
        ref={intersectionRef}
        className={classNames("image-skeleton", className, {
          "image-skeleton__square": shape === "square",
          "image-skeleton__circle": shape === "circle",
        })}
        style={
          style || {
            width: `${width}px`,
            height: `${height}px`,
            borderRadius: `${borderRadius}px`,
            borderTopLeftRadius: `${borderTopLeftRadius}px`,
            borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
            background: background,
            border: showBorder && (borderRadius || borderTopLeftRadius || borderBottomLeftRadius) && border,
          }
        }
      >
        {isInView && (
          <React.Fragment>
            <img
              className={classNames("image-skeleton__image", "thumb", imageThumbClassName, {
                isLoaded: !!isLoaded,
              })}
              src={__hotfixIsTankhouse ? src : transformImageSize(src, 10, 10)}
              alt="komi"
              style={!isValidHttpUrl(src) ? { display: "none" } : {}}
            />
            <img
              className={classNames("image-skeleton__image", "original", imageClassName, {
                isLoaded: !!isLoaded,
              })}
              style={__hotfixIsTankhouse ? { objectPosition: "top", marginTop: "-24px" } : {}}
              src={__hotfixIsTankhouse ? src : transformImageSize(src, Math.round(width) * 2, Math.round(height) * 2)}
              onLoad={handleOnLoad}
              alt="komi"
            />
          </React.Fragment>
        )}
        <Skeleton.Avatar
          className={classNames(skeletonClassName, {
            "d--none": isLoaded && !!src,
          })}
          active={!isLoaded && !!src}
          shape={shape}
          size={width}
          style={{
            width: `${width * 2}px`,
            height: `${height * 2}px`,
            borderRadius: `${borderRadius}px`,
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default ImageProgessive;
