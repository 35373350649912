import React from "react";

import { MusicItemType, PodcastItemType, ShopifyItemType } from "redux/User/types";

// import { ReactComponent as spotifyMusic } from "public/static/assets/icons/spotify-music";
// import { ReactComponent as appleMusic } from "public/static/assets/icons/apple-music";
// import { ReactComponent as amazoneMusic } from "public/static/assets/icons/amazone-music";
// import { ReactComponent as deezerMusic } from "public/static/assets/icons/deezer-music";
// import { ReactComponent as soundcloudMusic } from "public/static/assets/icons/soundcloud-music";
// import { ReactComponent as youtubeMusic } from "public/static/assets/icons/youtube-music";
// import { ReactComponent as pandoraMusic } from "public/static/assets/icons/pandora-music";
// import { ReactComponent as tidalMusic } from "public/static/assets/icons/tidal-music";
// import { ReactComponent as audiomackMusic } from "public/static/assets/icons/audiomack-music";
// import { ReactComponent as napsterMusic } from "public/static/assets/icons/napster-music";
// import { ReactComponent as bandcampMusic } from "public/static/assets/icons/bandcamp-music";
// import { ReactComponent as qobuzMusic } from "public/static/assets/icons/qobuz-music";
// import { ReactComponent as siriusxmMusic } from "public/static/assets/icons/siriusxm-music";
// import { ReactComponent as gaanaMusic } from "public/static/assets/icons/gaana-music";
// import { ReactComponent as beatportMusic } from "public/static/assets/icons/beatport-music";
// import { ReactComponent as tiktokMusic } from "public/static/assets/icons/tiktok-music";
// import { ReactComponent as iheartradioMusic } from "public/static/assets/icons/iheartradio-music";
// import { ReactComponent as anghammiMusic } from "public/static/assets/icons/anghammi-music";
// import { ReactComponent as itunesMusic } from "public/static/assets/icons/itunes-music";
// import { ReactComponent as amazonePhysicalMusic } from "public/static/assets/icons/amazone-physical-music";
// import { ReactComponent as hvmMusic } from "public/static/assets/icons/hvm-music";
// import { ReactComponent as targetMusic } from "public/static/assets/icons/target-music";
// import { ReactComponent as walmartMusic } from "public/static/assets/icons/walmart-music";
// import { ReactComponent as urbanMusic } from "public/static/assets/icons/urban-music";
// import { ReactComponent as recordMusic } from "public/static/assets/icons/record-music";
// import { ReactComponent as officalStoreMusic } from "public/static/assets/icons/offical-store-music";
// import { ReactComponent as appleModcast } from "public/static/assets/icons/apple-podcast";
// import { ReactComponent as Mudible } from "public/static/assets/icons/audible";
// import { ReactComponent as Mvercast } from "public/static/assets/icons/overcast";
// import { ReactComponent as Stitcher } from "public/static/assets/icons/stitcher";

export const MUSIC_LOGOS: any = {
  [MusicItemType.SPOTIFY]: "https://images.komi.io/production/icons/spotify-music",
  [MusicItemType.APPLE_MUSIC]: "https://images.komi.io/production/icons/apple-music",
  [MusicItemType.AMAZON_MUSIC]: "https://images.komi.io/production/icons/amazone-music",
  [MusicItemType.DEEZER]: "https://images.komi.io/production/icons/deezer-music",
  [MusicItemType.SOUNDCLOUD]: "https://images.komi.io/production/icons/soundcloud-music",
  [MusicItemType.YOUTUBE_MUSIC]: "https://images.komi.io/production/icons/youtube-music",
  [MusicItemType.PANDORA]: "https://images.komi.io/production/icons/pandora-music",
  [MusicItemType.TIDAL]: "https://images.komi.io/production/icons/tidal-music",
  [MusicItemType.AUDIOMACK]: "https://images.komi.io/production/icons/audiomack-music",
  [MusicItemType.NAPSTER]: "https://images.komi.io/production/icons/napster-music",
  [MusicItemType.BANDCAMP]: "https://images.komi.io/production/icons/bandcamp-music",
  [MusicItemType.QOBUZ]: "https://images.komi.io/production/icons/qobuz-music",
  [MusicItemType.SIRIUSXM]: "https://images.komi.io/production/icons/siriusxm-music",
  [MusicItemType.GAANA]: "https://images.komi.io/production/icons/gaana-music",
  [MusicItemType.BEATPORT]: "https://images.komi.io/production/icons/beatport-music",
  [MusicItemType.TIKTOK]: "https://images.komi.io/production/icons/tiktok-music",
  [MusicItemType.IHEARTRADIO]: "https://images.komi.io/production/icons/iheartradio-music",
  [MusicItemType.ANGHAMMI]: "https://images.komi.io/production/icons/anghammi-music",
  [MusicItemType.ITUNES_DIGITAL_DOWNLOAD]: "https://images.komi.io/production/icons/itunes-music",
  [MusicItemType.AMAZONE_DIGITAL_DOWNLOAD]: "https://images.komi.io/production/icons/amazone-physical-music",
  [MusicItemType.HMV]: "https://images.komi.io/production/icons/hvm-music",
  [MusicItemType.TARGET]: "https://images.komi.io/production/icons/target-music",
  [MusicItemType.WALMART]: "https://images.komi.io/production/icons/walmart-music",
  [MusicItemType.URBAN_OUTFITTERS]: "https://images.komi.io/production/icons/urban-music",
  [MusicItemType.RECORD_STORE]: "https://images.komi.io/production/icons/record-music",
  [MusicItemType.AMAZONE_PHYSICAL_PURCHASE]: "https://images.komi.io/production/icons/amazone-physical-music",
  [MusicItemType.OFFICIAL_STORE]: "https://images.komi.io/production/icons/offical-store-music",
};

export const PODCAST_LOGOS: any = {
  [PodcastItemType.APPLE_PODCAST]: "https://images.komi.io/production/icons/apple-podcast",
  [PodcastItemType.AUDIBLE]: "https://images.komi.io/production/icons/audible",
  [PodcastItemType.OVERCAST]: "https://images.komi.io/production/icons/overcast",
  [PodcastItemType.SPOTIFY]: "https://images.komi.io/production/icons/spotify-music",
  [PodcastItemType.STITCHER]: "https://images.komi.io/production/icons/stitcher",
  [PodcastItemType.AMAZON_MUSIC]: "https://images.komi.io/production/icons/amazone-music",
};

export const STREAMING_TYPES = [
  MusicItemType.SPOTIFY,
  MusicItemType.APPLE_MUSIC,
  MusicItemType.AMAZON_MUSIC,
  MusicItemType.DEEZER,
  MusicItemType.SOUNDCLOUD,
  MusicItemType.YOUTUBE_MUSIC,
  MusicItemType.PANDORA,
  MusicItemType.TIDAL,
  MusicItemType.AUDIOMACK,
  MusicItemType.NAPSTER,
  MusicItemType.BANDCAMP,
  MusicItemType.QOBUZ,
  MusicItemType.SIRIUSXM,
  MusicItemType.BEATPORT,
  MusicItemType.GAANA,
  MusicItemType.TIKTOK,
  MusicItemType.IHEARTRADIO,
  MusicItemType.ANGHAMMI,
];

export const DIGITAL_DOWNLOAD_TYPES = [MusicItemType.ITUNES_DIGITAL_DOWNLOAD, MusicItemType.AMAZONE_DIGITAL_DOWNLOAD];

export const PHYSICAL_PURCHASE_TYPES = [
  MusicItemType.HMV,
  MusicItemType.TARGET,
  MusicItemType.WALMART,
  MusicItemType.URBAN_OUTFITTERS,
  MusicItemType.RECORD_STORE,
  MusicItemType.AMAZONE_PHYSICAL_PURCHASE,
  MusicItemType.OFFICIAL_STORE,
];
