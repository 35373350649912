import { Text } from "components/Typography";
import { PODCAST_LOGOS } from "constants/music";
import { LIGHT_THEME } from "constants/profile-theme";
import { SEGMENT_EVENT } from "constants/segment";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import React, { useCallback, useMemo } from "react";
import SVG from "react-inlinesvg";
import { useTypedSelector } from "redux/rootReducer";
import { Talent } from "redux/Talent/types";
import { selectUserData } from "redux/User/selector";
import { PodcastAutomationItem, PodcastItemLink, TalentProfileModule } from "redux/User/types";

import { PodcastItemType } from "redux/User/types";
import { KOMI_USER_LOCATION } from "services/UserService";
import { AnalyticServices } from "utils/analytics";
const Row = dynamic(() => import("antd/lib/grid/row"));
const Col = dynamic(() => import("antd/lib/grid/col"));
const Button = dynamic(() => import("antd/lib/button"));

interface PodcastItemIProps {
  isMobile: boolean;
  module: TalentProfileModule;
  podcast: PodcastItemLink;
  podcastItem: PodcastAutomationItem;
  isSingleElement?: boolean;
  talent?: Talent;
}

const PodcastItem: React.FC<PodcastItemIProps> = ({
  podcast,
  talent,
  isMobile,
  isSingleElement,
  module,
  podcastItem,
}) => {
  const user = useTypedSelector(selectUserData);

  const handlePlay = useCallback(() => {
    if ((window as any)?.analytics) {
      AnalyticServices.track(SEGMENT_EVENT.CLICK_PODCAST_PROVIDER, {
        "User id": user?.id,
        Name: user ? `${user?.firstName} ${user?.lastName}` : undefined,
        Location: Cookies.get(KOMI_USER_LOCATION),
        "Talent ID": talent?.id,
        "Module ID": module?.id,
        "Module Name": module?.name,
        "Element ID": podcastItem?.id,
        "Element Name": podcastItem?.trackName,
        Destination: podcast?.type,
        "Smart Link URL": podcast?.url,
        Platform: isMobile ? "Responsive" : "Web",
        "Page ID": module?.localizationId || null,
        "Page Name": module?.localizationName || "Default",
        "Is Automated": "Yes",
      });
    }
    window.open(podcast?.externalUrl, "_blank");
  }, [
    isMobile,
    module?.id,
    module?.localizationId,
    module?.localizationName,
    module?.name,
    podcast,
    podcastItem,
    talent?.id,
    user,
  ]);

  const isDarken = useMemo(
    () => talent?.talentProfile?.themeColor?.overlayColor === LIGHT_THEME.overlayColor,
    [talent?.talentProfile?.themeColor?.overlayColor],
  );
  return (
    <Row
      className={`music-item ${isSingleElement ? "" : isMobile ? "p__y--16" : "p__y--24"}`}
      justify="space-between"
      align="middle"
    >
      <Col>
        <SVG
          className={isDarken ? "darken" : ""}
          src={`${PODCAST_LOGOS[podcast.type]}${
            isSingleElement && podcast.type !== PodcastItemType.AUDIBLE ? "-white" : ""
          }.svg`}
        />
      </Col>
      <Col>
        <Button className={`d--flex music-item${isSingleElement ? "--single" : ""}`} shape="round" onClick={handlePlay}>
          <Text preset={isMobile ? "medium12" : "semibold14"}>Play</Text>
        </Button>
      </Col>
    </Row>
  );
};

export default PodcastItem;
