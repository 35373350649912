import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import { TypographyPresets } from "components/Typography";
import { ReactComponent as LeftIcon } from "public/static/assets/icons/sLeft.svg";
import { ReactComponent as RightIcon } from "public/static/assets/icons/sRight.svg";
import { ReactComponent as ArrowRight } from "public/static/assets/icons/arrow-right-white.svg";
import { ReactComponent as ArrowLeft } from "public/static/assets/icons/arrow-left-white.svg";
import React, { ReactNode, useMemo, useState } from "react";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { Swiper as SwiperJS, SwiperSlide } from "swiper/react";
import LabelTooltip from "components/LabelTooltip/LabelTooltip";
import take from "lodash/take";
import { TalentProfileModuleType } from "redux/User/types";
SwiperCore.use([Navigation, Mousewheel]);

interface IProps {
  showShadow?: boolean;
  isMobile: boolean;
  title?: string;
  className?: string;
  params: any;
  items: ReactNode[];
  onSlideChange?(): void;
  speed?: number;
  titlePreset?: TypographyPresets;
  loop?: boolean;
  customNavigation?: boolean;
  isGroup?: boolean;
  showTitle?: boolean;
  typeModule?: string;
  shadowRight?: boolean;
}
const NUMBER_ITEMS = 12;
const Swiper: React.FC<IProps> = ({
  className = "",
  params,
  items,
  title = "",
  isMobile,
  showShadow = false,
  onSlideChange,
  speed = 700,
  titlePreset,
  loop = false,
  customNavigation = false,
  isGroup,
  showTitle,
  typeModule,
}) => {
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);
  const [hasShadowLeft, setHasShadowLeft] = useState(false);
  const [hasShadowRight, setHasShadowRight] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const classNames = useMemo(
    () => `my-swiper ${(!params?.slidesPerColumn || params?.slidesPerColumn <= 1) && "single"} ${className}`,
    [className, params?.slidesPerColumn],
  );

  const minIndex = useMemo(() => (params?.slidesPerView > 3 ? 1 : 0), [params]);
  const maxIndex = useMemo(() => {
    if (params?.slidesPerView === 1) {
      return 1;
    }
    return params?.slidesPerView > 3 ? items?.length - 3 : items?.length - 1;
  }, [items?.length, params?.slidesPerView]);
  const itemsSwiperSlide = useMemo(() => {
    if (isMobile) {
      return items;
    }
    if (items.length <= NUMBER_ITEMS) {
      return items;
    }
    const columns: number = params?.slidesPerColumn || 1;
    const limit = NUMBER_ITEMS / columns;
    const page = parseInt((((activeIndex + limit) * columns) / NUMBER_ITEMS).toString());
    return isMobile ? items : take(items, (page + 1) * NUMBER_ITEMS);
  }, [isMobile, activeIndex, params?.slidesPerColumn, items]);

  const showArrowIconByItem = () => {
    if (
      typeModule === TalentProfileModuleType.YOUTUBE_VIDEO ||
      typeModule === TalentProfileModuleType.YOUTUBE_COLLECTION ||
      typeModule === TalentProfileModuleType.ON_DEMAND_VIDEO
    ) {
      return 1;
    } else {
      return 3;
    }
  };
  return params ? (
    <div className={classNames}>
      {((params?.navigation && items?.length > showArrowIconByItem()) ||
        (title?.length > 0 && (isGroup ? showTitle : true))) &&
        (items?.length > 1 || (title?.length > 0 && (isGroup ? showTitle : true))) && (
          <div className={` ${isGroup ? (isMobile ? "p__b--8" : "p__b--16") : isMobile ? "p__b--8" : "p__b--20"}`}>
            <Row justify="space-between" align="middle">
              <Col className="p__r--24">
                {!(isGroup && !showTitle) && (
                  <LabelTooltip
                    className="my-swiper__title"
                    preset={
                      titlePreset || isGroup
                        ? isMobile
                          ? "semibold18"
                          : "semibold20"
                        : isMobile
                        ? "semibold24"
                        : "semibold28"
                    }
                    label={title}
                    ellipsis={{ rows: 1 }}
                    maxLength={isMobile ? title.length : 30}
                  />
                )}
              </Col>
              {params?.navigation && items?.length > showArrowIconByItem() && (
                <Col className="btn-swiper__wrapper">
                  <Button ref={prevRef} type="text" size="small" className={`btn-swiper text--white p__x--0`}>
                    <LeftIcon width={24} height={24} />
                  </Button>
                  <Button
                    ref={nextRef}
                    type="text"
                    size="small"
                    className={`btn-swiper text--white ${isMobile ? "m__l--8" : "m__l--16"} p__x--0`}
                  >
                    <RightIcon width={24} height={24} />
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        )}
      {customNavigation && (
        <div className="slider__btn d--flex">
          <Button ref={prevRef} type="text" size="small" className={`btn-swiper text--white p__x--0`}>
            <ArrowLeft className="cursor-pointer" />
          </Button>
          <Button
            ref={nextRef}
            type="text"
            size="small"
            className={`btn-swiper text--white ${isMobile ? "m__l--8" : "m__l--16"} p__x--0`}
          >
            <ArrowRight className="cursor-pointer" />
          </Button>
        </div>
      )}
      <div className="my-swiper__content">
        {showShadow && (
          <>
            {hasShadowLeft && <div className="shadow-left" />}
            {hasShadowRight && <div className="shadow-right" />}
          </>
        )}
        <SwiperJS
          {...params}
          speed={speed}
          navigation={false}
          loop={loop}
          onBeforeInit={(swiper) => {
            if (swiper?.params?.navigation) {
              (swiper.params.navigation as any).prevEl = prevRef.current;
              (swiper.params.navigation as any).nextEl = nextRef.current;
            }
            if (maxIndex === 1) {
              setHasShadowRight(false);
            }
          }}
          className={`${params?.noSwiping && "swiper-no-swiping"} `}
          onActiveIndexChange={(swiper) => {
            setHasShadowLeft(swiper.activeIndex > minIndex);
            setHasShadowRight(!swiper.isEnd);
            if (onSlideChange) onSlideChange();
            if (swiper.activeIndex > activeIndex) {
              setActiveIndex(swiper.activeIndex);
            }
          }}
        >
          {itemsSwiperSlide?.map((item, i) => (
            <SwiperSlide key={i}>{item}</SwiperSlide>
          ))}
        </SwiperJS>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Swiper;
