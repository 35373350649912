import Tooltip from "antd/lib/tooltip";
import { Paragraph, ParagraphProps } from "components/Typography";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { stringReplaceBetween } from "utils/string";

interface LabelTooltipProps extends ParagraphProps {
  label: string;
  maxLength: number;
  replaceBetween?: boolean;
  tag?: string;
}

const LabelTooltip: React.FC<LabelTooltipProps> = ({ label, maxLength, replaceBetween = false, tag, ...props }) => {
  const [labelCut, setLabelCut] = useState("");

  useEffect(() => {
    setLabelCut(
      label?.length > maxLength
        ? replaceBetween
          ? stringReplaceBetween(label, "...", Math.ceil(maxLength / 2), label.length - Math.ceil(maxLength / 2))
          : `${label.slice(0, maxLength)}...`
        : label,
    );
  }, [label, maxLength]);

  return (
    <React.Fragment>
      {labelCut === label ? (
        <div className="d--flex align__items--center">
          <Paragraph {...props}>{labelCut}</Paragraph>
          {tag && <div className={`m__l--8 ${props.className ? `${props.className}--tag` : ""}`}>{tag}</div>}
        </div>
      ) : (
        <div className="d--flex align__items--center">
          <Tooltip placement="bottom" title={label}>
            <Paragraph {...props}>{labelCut}</Paragraph>
          </Tooltip>
          {tag && <div className={`m__l--8 ${props.className ? `${props.className}--tag` : ""}`}>{tag}</div>}
        </div>
      )}
    </React.Fragment>
  );
};

export default LabelTooltip;
